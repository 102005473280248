import React from "react";
import { Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import {
  BarChartOutlined,
  LockOutlined,
  AimOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  ControlOutlined,
  BankOutlined,
  UserOutlined,
  ApiOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const { SubMenu } = Menu;

export default ({ isMobile }) => {
  const history = useHistory();

  let selected =
    (history.location.pathname.split("/")[1] || "") +
    "-" +
    (history.location.pathname.split("/")[2] || "");

  return (
    <Container>
      <Menu
        defaultSelectedKeys={[selected]}
        mode="inline"
        theme="light"
        inlineCollapsed={isMobile}
      >
        <Menu.Item key="-" icon={<BarChartOutlined />}>
          Дашбоард <NavLink to="/" />
        </Menu.Item>
        <Menu.Item key="connection" icon={<ApiOutlined />}>
          Систем холболт <NavLink to="/connection" />
        </Menu.Item>
        <Menu.Item key="message" icon={<AimOutlined />}>
          Хүсэлтийн жагсаалт <NavLink to="/message" />
        </Menu.Item>
        <SubMenu
          key="transaction"
          title="Гүйлгээний мэдээлэл"
          icon={<FileDoneOutlined />}
        >
          <Menu.Item key="transaction-card">
            Карт гүйлгээ <NavLink to="/transaction/card" />
          </Menu.Item>
          <Menu.Item key="transaction-account">
            Данс гүйлгээ <NavLink to="/transaction/account" />
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="refund"
          title="Буцаалтын мэдээлэл"
          icon={<FileSyncOutlined />}
        >
          <Menu.Item key="refund-card">
            Карт гүйлгээ <NavLink to="/refund/card" />
          </Menu.Item>
          <Menu.Item key="refund-account">
            Данс гүйлгээ <NavLink to="/refund/account" />
          </Menu.Item>
        </SubMenu>
        <SubMenu key="fee" title="Шимтгэл удирдлага" icon={<ControlOutlined />}>
          <Menu.Item key="fee-settings">
            Шимтгэл тохиргоо <NavLink to="/fee/settings" />
          </Menu.Item>
          <Menu.Item key="fee-calculation">
            Шимтгэл тооцоолол <NavLink to="/fee/calculation" />
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="operator"
          title="Харилцагч удирдлага"
          icon={<BankOutlined />}
        >
          <Menu.Item key="operator-list">
            Харилцагч <NavLink to="/operator/list" />
          </Menu.Item>
          <Menu.Item key="user-list-OPERATOR">
            Хэрэглэгч <NavLink to="/user/list/OPERATOR" />
          </Menu.Item>
        </SubMenu>

        <SubMenu key="report" title="Тайлан" icon={<ReconciliationOutlined />}>
          <Menu.Item key="report-monthly_bill">
            Monthly Billing Invoice
            <NavLink to="/report/monthly_bill" />
          </Menu.Item>
          <Menu.Item key="report-unsuccessful_trans">
            UnSuccessful Transactions{" "}
            <NavLink to="/report/unsuccessful_trans" />
          </Menu.Item>
          <Menu.Item key="report-successful_trans">
            Successful Transactions <NavLink to="/report/successful_trans" />
          </Menu.Item>
          <Menu.Item key="/report/net_settlement">
            Net Settlement <NavLink to="/report/net_settlement" />
          </Menu.Item>
          <Menu.Item key="/report/reconcilation">
            Reconcilation <NavLink to="/report/reconcilation" />
          </Menu.Item>
          <Menu.Item key="/report/monthly/successful_trans">
            Monthly Successful Transactions{" "}
            <NavLink to="/report/monthly/successful_trans" />
          </Menu.Item>
          <Menu.Item key="/report/monthly/unsuccessful_trans">
            Monthly UnSuccessful Transactions{" "}
            <NavLink to="/report/monthly/unsuccessful_trans" />
          </Menu.Item>
        </SubMenu>

        <hr />

        <Menu.Item key="user-list-ADMIN" icon={<UserOutlined />}>
          Систем хэрэглэгч <NavLink to="/user/list/ADMIN" />
        </Menu.Item>
        <Menu.Item key="user-role" icon={<LockOutlined />}>
          Хандах эрх <NavLink to="/user/role" />
        </Menu.Item>
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  z-index: 1000;
  padding-top: 20px;
  .project {
    padding: 20px;
    width: 100%;
    &.mobile {
      .ant-btn-group {
        .ant-btn:first-child {
          display: none !important;
        }
      }
    }
    .ant-btn-group {
      width: 100%;
      .ant-btn:last-child {
        width: 39px !important;
      }
      .ant-btn:first-child {
        width: 100%;
      }
    }
  }
  .ant-menu {
    border-right: none;

    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;
