import React from "react";
import { PageHeader, PageContent } from "../../components/Layout";
import { Form, Input, Select } from "formik-antd";
import { Button, message, Tag } from "antd";
import { Formik } from "formik";
import { feeSettings as feeSettingsApi } from "../../apis";
import { useHistory } from "react-router-dom";
import { RowAction } from "../../components";
import * as yup from "yup";
import styled from "styled-components";
import { tugrug } from "../../utils";

const { Option } = Select;

const FeeSchema = yup.object().shape({
  type             : yup.string().required(),
  acquirer_fee_type: yup.string().when("type", {
    is  : "acquirer",
    then: yup.string().required(" ")
  }),
  acquirer_fee: yup.number().when("type", {
    is  : "acquirer",
    then: yup.number().required(" ")
  }),
  acquirer_min_amount: yup.number().when("type", {
    is  : "acquirer",
    then: yup.number().required(" ")
  }),
  acquirer_max_amount: yup.number().when("type", {
    is  : "acquirer",
    then: yup.number().required(" ")
  }),
  issuer_fee_type: yup.string().when("type", {
    is  : "issuer",
    then: yup.string().required(" ")
  }),
  issuer_fee: yup.number().when("type", {
    is  : "issuer",
    then: yup.number().required(" ")
  }),
  issuer_min_amount: yup.number().when("type", {
    is  : "issuer",
    then: yup.number().required(" ")
  }),
  issuer_max_amount: yup.number().when("type", {
    is  : "issuer",
    then: yup.number().required(" ")
  }),
  bom_fee_type: yup.string().when("type", {
    is  : "bom",
    then: yup.string().required(" ")
  }),
  bom_fee: yup.number().when("type", {
    is  : "bom",
    then: yup.number().required(" ")
  }),
  bom_min_amount: yup.number().when("type", {
    is  : "bom",
    then: yup.number().required(" ")
  }),
  bom_max_amount: yup.number().when("type", {
    is  : "bom",
    then: yup.number().required(" ")
  }),
  epay_fee_type: yup.string().when("type", {
    is  : "epay",
    then: yup.string().required(" ")
  }),
  epay_fee: yup.number().when("type", {
    is  : "epay",
    then: yup.number().required(" ")
  }),
  epay_min_amount: yup.number().when("type", {
    is  : "epay",
    then: yup.number().required(" ")
  }),
  epay_max_amount: yup.number().when("type", {
    is  : "epay",
    then: yup.number().required(" ")
  }),
});

const FormSchema = () => yup.object().shape({
  inbank_payment    : FeeSchema,
  inbank_purchase   : FeeSchema,
  inbank_transfer   : FeeSchema,
  interbank_payment : FeeSchema,
  interbank_purchase: FeeSchema,
  interbank_transfer: FeeSchema
});

export default ({ action: payload }) => {
  const [data, setFormData] = React.useState({
    ...(payload && payload[0] === "update" ? payload[1] : {})
  });
  const [data2, setFormData2] = React.useState({
    ...(payload && payload[0] === "update" ? payload[1] : {})
  });

  const history = useHistory();
  const [action, setAction] = React.useState([]);

  const FEE_TYPES = {
    type: "Төрөл",
    fee : "Шимтгэл",
    min : "Бага дүн",
    max : "Их дүн"
  };

  const onSubmit = async (values)=> {
    const reduce = (array, mode, type) => {
      return array.reduce((accumulator, iterator, index) => {
        const field = values[`${mode}_${type}`].type;

        return [
          ...accumulator,
          index === action[1] ? {
            ...iterator,
            interval               : action[1] + 1,
            [`${field}_fee_type`]  : values[`${mode}_${type}`][`${`${field}_fee_type`}`],
            [`${field}_fee`]       : values[`${mode}_${type}`][`${`${field}_fee`}`],
            [`${field}_min_amount`]: values[`${mode}_${type}`][`${`${field}_min_amount`}`],
            [`${field}_max_amount`]: values[`${mode}_${type}`][`${`${field}_max_amount`}`],
          } : iterator
        ];
      }, []);
    };

    let payload = {
      ...data,
      inbank_payment_intervals    : reduce(data.inbank_payment_intervals, "inbank", "payment"),
      inbank_purchase_intervals   : reduce(data.inbank_purchase_intervals, "inbank", "purchase"),
      inbank_transfer_intervals   : reduce(data.inbank_transfer_intervals, "inbank", "transfer"),
      interbank_payment_intervals : reduce(data.interbank_payment_intervals, "interbank", "payment"),
      interbank_purchase_intervals: reduce(data.interbank_purchase_intervals, "interbank", "purchase"),
      interbank_transfer_intervals: reduce(data.interbank_transfer_intervals, "interbank", "transfer"),
    };

    setFormData(payload);

    setAction([]);

    message.warning("Тохиргоог түр хадгаллаа!");
  };

  const initFee = (fee, type) => {
    let values = {
      ...({
        type,
        acquirer_fee_type  : "FLAT",
        acquirer_fee       : undefined,
        acquirer_min_amount: undefined,
        acquirer_max_amount: undefined,
        issuer_fee_type    : undefined,
        issuer_fee         : undefined,
        issuer_min_amount  : undefined,
        issuer_max_amount  : undefined,
        bom_fee_type       : undefined,
        bom_fee            : undefined,
        bom_min_amount     : undefined,
        bom_max_amount     : undefined,
        epay_fee_type      : undefined,
        epay_fee           : undefined,
        epay_min_amount    : undefined,
        epay_max_amount    : undefined
      }),
      ...fee
    };

    return Object.keys(values).reduce((accumulator, key) => {
      return {
        ...accumulator,
        [key]: values[key] === null ? undefined : values[key]
      };
    }, {});
  };

  const onAction = (type, fee, interval) => {
    switch (type) {
      case "edit": {
        setAction([fee, interval, {
          inbank_purchase   : initFee(data.inbank_purchase_intervals[interval], fee),
          inbank_payment    : initFee(data.inbank_payment_intervals[interval], fee),
          inbank_transfer   : initFee(data.inbank_transfer_intervals[interval], fee),
          interbank_payment : initFee(data.interbank_payment_intervals[interval], fee),
          interbank_purchase: initFee(data.interbank_purchase_intervals[interval], fee),
          interbank_transfer: initFee(data.interbank_transfer_intervals[interval], fee)
        }]);

        console.log(action);
        break;
      }
      default:
    }
  };

  const cancelAction = async () => {
    setFormData(data2);
    message.warning("Түр хадгалсан тохиргоог устгалаа!");
  };
  const onSave = async () => {
    let settings = [{
      transaction_type: "PURCHASE",
      transaction_mode: "INBANK",
      intervals       : data.inbank_purchase_intervals
    },
    {
      transaction_type: "PAYMENT",
      transaction_mode: "INBANK",
      intervals       : data.inbank_payment_intervals
    },
    {
      transaction_type: "TRANSFER",
      transaction_mode: "INBANK",
      intervals       : data.inbank_transfer_intervals
    },
    {
      transaction_type: "PURCHASE",
      transaction_mode: "INTERBANK",
      intervals       : data.interbank_purchase_intervals
    },
    {
      transaction_type: "PAYMENT",
      transaction_mode: "INTERBANK",
      intervals       : data.interbank_payment_intervals
    },
    {
      transaction_type: "TRANSFER",
      transaction_mode: "INTERBANK",
      intervals       : data.interbank_transfer_intervals
    }];

    try {
      await feeSettingsApi.update(data.operator_id, {
        settings,
        note: "."
      });

      message.success("Таны хүсэлт амжилттай!");
      history.push("/fee/settings");
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }
  };

  const renderField = (fee, interval, mode, type, field) => {
    let name = `${mode}_${type}.${fee}`;
    let intervals = data[`${mode}_${type}_intervals`];
    switch (field) {
      case "type": {
        if (fee !== action[0] || interval !== action[1])
          return intervals[interval] && intervals[interval][`${fee}_fee_type`] || "-";

        return (
          <>
            <Input type="hidden" name={`${mode}_${type}.type`} />
            <Form.Item name={`${name}_fee_type`} style={{ width: "100%" }}>
              <Select name={`${name}_fee_type`} placeholder="Төрөл" style={{ width: "100%" }}>
                <Option value="FLAT">Дүн</Option>
                <Option value="PERCENT">Хувь</Option>
              </Select>
            </Form.Item>
          </>
        );
      }
      case "fee": {
        if (intervals[interval][`${fee}_fee_type`] == "PERCENT"){
          if (fee !== action[0] || interval !== action[1])
            return intervals[interval] && intervals[interval][`${fee}_fee`]+"%";
        } else if (fee !== action[0] || interval !== action[1]){
          return intervals[interval] && tugrug(intervals[interval][`${fee}_fee`]);
        }
        return (
          <Form.Item name={`${name}_fee`} style={{ width: "100%" }}>
            <Input type="number" name={`${name}_fee`} placeholder="Шимтгэл" style={{ width: "100%" }} />
          </Form.Item>
        );
      }
      case "min": {
        if (fee !== action[0] || interval !== action[1])
          return intervals[interval] && tugrug(intervals[interval][`${fee}_min_amount`]);

        return (
          <Form.Item name={`${name}_min_amount`} style={{ width: "100%" }}>
            <Input type="number" name={`${name}_min_amount`} placeholder="Бага дүн" style={{ width: "100%" }} />
          </Form.Item>
        );
      }
      case "max": {
        if (fee !== action[0] || interval !== action[1])
          return intervals[interval] && tugrug(intervals[interval][`${fee}_max_amount`]);

        return (
          <Form.Item name={`${name}_max_amount`} style={{ width: "100%" }}>
            <Input type="number" name={`${name}_max_amount`} placeholder="Их дүн" style={{ width: "100%" }} />
          </Form.Item>
        );
      }
      default:
        return <></>;
    }
  };

  const renderBody = (fee) => {
    return (
      <tbody className="ant-table-tbody">
        {[0, 1, 2, 3].map((interval) => {
          return (
            <>
              {["type", "fee", "min", "max"].map((field, index) => {
                return (
                  <tr key={index}>
                    {field === "type" && interval === 0 && <td className="ant-table-cell border-bottom border-first border-last" style={{ color: "#f93040" }} rowSpan={16}>{fee.toUpperCase()}</td>}
                    {field === "type" && <td className="ant-table-cell border-bottom" rowSpan={4} width="120">Interval {interval + 1}</td>}
                    {field === "max" ? <td className="ant-table-cell border-bottom" rowSpan={1}>{FEE_TYPES[field]}</td> : <td className="ant-table-cell" rowSpan={1}>{FEE_TYPES[field]}</td>}
                    {/* {["inbank", "interbank"].map((mode) => { */}
                    {["interbank"].map((mode) => {
                      return (
                        <>
                          {["purchase", "payment", "transfer"].map((type, index) => {
                            return (
                              field === "max" ? <td className="ant-table-cell border-bottom" rowSpan={1} key={index}>
                                {renderField(fee, interval, mode, type, field, index)}
                              </td>
                                :
                                <td className="ant-table-cell" rowSpan={1} key={index}>
                                  {renderField(fee, interval, mode, type, field, index)}
                                </td>
                            );
                          })}
                        </>
                      );
                    })}
                    {field === "type" && (
                      <td className="ant-table-cell border-first" rowSpan={4} width={100}>
                        {!(action[0] === fee && action[1] === interval) ? (
                          <RowAction
                            actions = {{
                              edit: "Засварлах",
                            }}
                            onClick={(key) => onAction(key, fee, interval)}
                          />
                        ) : (
                          <>
                            <Button type="primary" style={{ width: "100%" }} htmlType="submit">Хадгалах</Button>
                            <Button type="default" style={{ width: "100%", marginTop: 10 }} onClick={() => setAction([])}>Болих</Button>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </>
          );
        })}
      </tbody>
    );
  };
  const renderBody2 = (fee) => {
    // console.log(data2);
    return (
      <tbody className="ant-table-tbody">
        {[0, 1, 2, 3].map((interval) => {
          return (
            <>
              {["type", "fee", "min", "max"].map((field, index) => {
                return (
                  <tr key={index}>
                    {field === "type" && interval === 0 && <td className="ant-table-cell" style={{ color: "#f93040" }} rowSpan={16}>{fee.toUpperCase()}</td>}
                    {field === "type" && <td className="ant-table-cell" rowSpan={4} width="120">Interval {interval + 1}</td>}
                    <td className="ant-table-cell" rowSpan={1}>{FEE_TYPES[field]}</td>
                    {/* {["inbank", "interbank"].map((mode) => { */}
                    {["interbank"].map((mode) => {
                      return (
                        <>
                          {["purchase", "payment", "transfer"].map((type, index) => {
                            return (
                              <td className="ant-table-cell" rowSpan={1} key={index}>
                                {renderField(fee, interval, mode, type, field, index)}
                              </td>
                            );
                          })}
                        </>
                      );
                    })}
                    {field === "type" && (
                      <td className="ant-table-cell" rowSpan={4} width={100}>
                        {!(action[0] === fee && action[1] === interval) ? (
                          <RowAction
                            actions = {{
                              edit: "Засварлах",
                            }}
                            onClick={(key) => onAction(key, fee, interval)}
                          />
                        ) : (
                          <>
                            <Button type="primary" style={{ width: "100%" }} htmlType="submit">Хадгалах</Button>
                            <Button type="default" style={{ width: "100%", marginTop: 10 }} onClick={() => setAction([])}>Болих</Button>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </>
          );
        })}
      </tbody>
    );
  };

  return (
    <PageContainer>
      <PageHeader title="Шимтгэл тохиргоо" extra={[
        <Button type="default" onClick={() => cancelAction()}>Болих</Button>,
        <Button type="primary" onClick={onSave}>Хадгалах</Button>
      ]} />
      <Formik
        enableReinitialize
        initialValues={action[2]}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {() => {
          return (
            <Form>
              <PageContent>
                <div style={{ height: 1 }} className="ant-table ant-table-bordered ant-table-scroll-horizontal">
                  <div className="ant-table-container">
                    <div className="ant-table-content" style={{ overflow: "auto hidden" }}>
                      <table style={{ width: "1000px", minWidth: "100%", tableLayout: "auto" } }>
                        <thead className="ant-table-thead sticky-header">
                          <tr>
                            <th className="ant-table-cell" rowSpan={2} colSpan={3}>
                              <div>{data?.operator_name}</div>
                              <Tag color="red" style={{ margin: 0 }}>{data.operator_code}</Tag>
                            </th>
                            {/* <th className="ant-table-cell" rowSpan={1} colSpan={3}>Inbank</th> */}
                            <th className="ant-table-cell" rowSpan={1} colSpan={3}>Interbank</th>
                            <th className="ant-table-cell" rowSpan={2}>Үйлдэл</th>
                          </tr>
                          <tr>
                            {/* <th className="ant-table-cell" rowSpan={1}>PURCHASE</th>
                            <th className="ant-table-cell" rowSpan={1}>PAYMENT</th>
                            <th className="ant-table-cell" rowSpan={1}>TRANSFER</th> */}
                            <th className="ant-table-cell" rowSpan={1}>PURCHASE</th>
                            <th className="ant-table-cell" rowSpan={1}>PAYMENT</th>
                            <th className="ant-table-cell" rowSpan={1}>TRANSFER</th>
                          </tr>
                        </thead>
                        {renderBody2("acquirer")}
                      </table>
                    </div>
                  </div>
                </div>
                <TableContainer>
                  <div className="ant-table ant-table-bordered ant-table-scroll-horizontal">
                    <div className="ant-table-container">
                      <div className="ant-table-content" style={{ overflow: "auto hidden" }}>
                        <table style={{ width: "1000px", minWidth: "100%", tableLayout: "auto" } }>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell" rowSpan={2} colSpan={3}>
                                <div>{data?.operator_name}</div>
                                <Tag color="red" style={{ margin: 0 }}>{data.operator_code}</Tag>
                              </th>
                              {/* <th className="ant-table-cell" rowSpan={1} colSpan={3}>Inbank</th> */}
                              <th className="ant-table-cell" rowSpan={1} colSpan={3}>Interbank</th>
                              <th className="ant-table-cell" rowSpan={2}>Үйлдэл</th>
                            </tr>
                            <tr>
                              {/* <th className="ant-table-cell" rowSpan={1}>PURCHASE</th>
                              <th className="ant-table-cell" rowSpan={1}>PAYMENT</th>
                              <th className="ant-table-cell" rowSpan={1}>TRANSFER</th> */}
                              <th className="ant-table-cell" rowSpan={1}>PURCHASE</th>
                              <th className="ant-table-cell" rowSpan={1}>PAYMENT</th>
                              <th className="ant-table-cell" rowSpan={1}>TRANSFER</th>
                            </tr>
                          </thead>
                          {["acquirer", "issuer", "bom", "epay"].map((name) => renderBody(name))}
                        </table>
                      </div>
                    </div>
                  </div>
                </TableContainer>
              </PageContent>
            </Form>
          );
        }}
      </Formik>
    </PageContainer>
  );
};
const TableContainer = styled.div`
  overflow: auto;
  max-height: 1000px;
`;

const PageContainer = styled.div`
  margin-top: 20px;
  .ant-table-cell {
    padding: 8px;  
  }
  .red {
    background-color: #ff4d4f;
  }
  .blue {
    background-color: #40a9ff;
  }
  .orange {
    background-color: #ffc53d;
  }
  .cayn {
    background-color: #36cfc9;
  }
  .purple  {
    background-color: #9254de;
  }
  .green {
    background-color: #73d13d;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-form-item-explain-error {
    display: none !important;
  }
  .sticky-header {
    position: sticky !important;
    top: 0;
    background: "#fafafa";
    z-index: 2;
  }
  .border-bottom{
    border-bottom: 1px solid #000 !important;
  }
  .border-first{
    border-left: 1px solid #000;
  }
  .border-last{
    border-right: 1px solid #000 !important;
  }
`;

