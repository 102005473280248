import React from "react";
import { Button, DatePicker, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import transactionTable from "../MyTable/transactionTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { accessToken } = useSelector((state) => state.auth);

  const [options, setOptions] = React.useState([]);

  const [value, setValue] = React.useState([]);

  React.useEffect(() => {
    console.log("value ==========> ", value);
    const allIncluded = value.includes("all");
    if ((options.length - 1) === value.length && !allIncluded) {
      setValue([...value, "all"]);
    } else if (allIncluded && value.length !== options.length) {
      setValue(value.filter((el) => el !== "all"));
    }
  }, [value, options.length]);

  React.useEffect(() => {
    fetchReportData("/operator/list", "GET", accessToken).then((res) => {
      var listData = [];
      if (res.responseCode) {
        for (let index = 0; index < res.data.length; index++) {
          const selectedData = res.data[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          selectedData.tableRef = React.createRef();
          selectedData.displayData = null;
          selectedData.tablePageData = {
            request: {
              pageNo : 1,
              perPage: 5,
              sort   : "",
            },
          };
          selectedData.headerIndexes = [];
          selectedData.setHeaderIndexes = (value) => {
            // console.log("setHeaderIndexes", value);
            selectedData.headerIndexes = [
              ...(selectedData.headerIndexes, value),
            ];
          };
          selectedData.subheaderIndexes = [];
          selectedData.setSubheaderIndexes = (value) => {
            // console.log("setSubheaderIndexes", value);
            selectedData.subheaderIndexes = [
              ...selectedData.subheaderIndexes,
              value,
            ];
          };
          listData.push(selectedData);
        }
        if (listData.length > 0){
          let data = {};
          data.label = "Бүгд";
          data.value = "all";
          listData.unshift(data);
        }
        // console.log("listData", listData);
        setOptions(listData);
      }
    });
  }, []);

  const [filters, setFilters] = React.useState({
    // startDate: "2020-04-11",
    // endDate  : "2026-04-11",
    startDate: "",
    endDate  : "",
  });

  // const useHeader = ({ type, onAction, s3host }) => {
  //   const sharedOnCell = (_, index) => {
  //     if (headerIndexes.includes(index) || subheaderIndexes.includes(index)) {
  //       return {
  //         colSpan: 0,
  //       };
  //     }
  //     return {
  //       colSpan: 1,
  //     };
  //   };
  //   return [
  //     {
  //       title : "Participant",
  //       align : "left",
  //       render: (record) => {
  //         return (
  //           <div
  //             style={{
  //               padding: "8px",
  //             }}
  //           >
  //             {record.key}
  //           </div>
  //         );
  //       },
  //       onCell: (_, index) => ({
  //         colSpan: headerIndexes.includes(index)
  //           ? 6
  //           : subheaderIndexes.includes(index)
  //             ? 5
  //             : 1,
  //         className: headerIndexes.includes(index)
  //           ? "header-cell"
  //           : subheaderIndexes.includes(index)
  //             ? "subheader-cell"
  //             : "cell-center",
  //       }),
  //     },
  //     {
  //       title: "Txn ID",
  //       render: (record, index) => {
  //         return <div style={{ padding: "8px" }}>{record.transactionCode}</div>;
  //       },
  //       onCell: sharedOnCell,
  //     },
  //     {
  //       title: "Date",
  //       render: (record) => {
  //         return (
  //           <div style={{ padding: "8px" }}>
  //             {record.date ? moment(record.date).format("YYYY-MM-DD") : "-"}
  //           </div>
  //         );
  //       },
  //       onCell: (_, index) => ({
  //         colSpan:
  //           headerIndexes.includes(index) || subheaderIndexes.includes(index)
  //             ? 0
  //             : 1,
  //       }),
  //     },
  //     {
  //       title: "ParticipantID/name",
  //       render: (record) => {
  //         return <div style={{ padding: "8px" }}>{record.bankCode}</div>;
  //       },
  //       onCell: (_, index) => ({
  //         colSpan:
  //           headerIndexes.includes(index) || subheaderIndexes.includes(index)
  //             ? 0
  //             : 1,
  //       }),
  //     },
  //     {
  //       title: "From/To account number",
  //       render: (record) => {
  //         return (
  //           <div style={{ padding: "8px" }}>{record.bankAccountNumber}</div>
  //         );
  //       },
  //       onCell: (_, index) => ({
  //         colSpan:
  //           headerIndexes.includes(index) || subheaderIndexes.includes(index)
  //             ? 0
  //             : 1,
  //       }),
  //     },
  //     {
  //       title: "Txn Amount",
  //       render: (record) => {
  //         return (
  //           <div style={{ padding: "8px" }}>{record.transactionAmount}</div>
  //         );
  //       },
  //       onCell: (_, index) => ({
  //         colSpan: headerIndexes.includes(index) ? 0 : 1,
  //         className: subheaderIndexes.includes(index) ? "subvalue-cell" : "",
  //       }),
  //     },
  //   ];
  // };

  // const columns = useHeader({});

  // console.log("columns", columns);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  React.useEffect(() => {
    if (value.length > 0 && filters.startDate != "" && filters.endDate != "") {
      value.forEach((el) => {
        getData(el);
      });
    }
  }, [filters]);

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setValue([]);
  };

  const getData = (optionValue) => {
    const idx = options.findIndex((option) => option.value === optionValue);
    if (options[idx].tableRef.current != null) {
      options[idx].tableRef.current.setLoading(true);
      options[idx].tablePageData.request.pageNo =
        options[idx].tableRef.current.getPage();
      options[idx].tablePageData.request.perPage =
        options[idx].tableRef.current.getLimit();
    }
    fetchReportData("/report/transaction", "POST", accessToken, {
      request          : options[idx].tablePageData.request,
      ...filters,
      operatorCode     : options[idx].code,
      transactionStatus: reportType,
    })
      .then((data) => {
        if (data.responseCode) {
          options[idx].displayData = data.data;
          setOptions([...options]);
        }
        if (options[idx].tableRef.current != null) {
          options[idx].tableRef.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (options[idx].tableRef.current != null) {
          options[idx].tableRef.current.setLoading(false);
        }
      });
  };

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Select
              mode="multiple"
              style={{ width: "200px" }}
              options={options}
              placeholder="Select issuer..."
              maxTagCount="responsive"
              value={value}
              onChange={(val) => {
                if (
                  filters.startDate === "" ||
                  filters.startDate === null ||
                  filters.endDate === "" ||
                  filters.endDate === null
                ) {
                  message.error("Эхлэх хугацаа, дуусах хугацааг сонгоно уу.");
                  return;
                }
                const lostOne = value.filter((item) => !val.includes(item));
                if (lostOne.length > 0) {
                  if (lostOne[0] === "all") {
                    if (value.length === options.length) {
                      for (let index = 0; index < options.length; index++) {
                        const element = options[index];
                        element.displayData = null;
                        element.tablePageData = {
                          request: {
                            pageNo : 1,
                            perPage: 5,
                            sort   : "",
                          },
                        };
                        element.headerIndexes = [];
                        element.subheaderIndexes = [];
                      }
                      setValue([]);
                      return;
                    }
                  }
                  const lostOptionIndex = options.findIndex(
                    (option) => option.value === lostOne[0]
                  );
                  options[lostOptionIndex].displayData = null;
                  options[lostOptionIndex].tablePageData = {
                    request: {
                      pageNo : 1,
                      perPage: 5,
                      sort   : "",
                    },
                  };
                  options[lostOptionIndex].headerIndexes = [];
                  options[lostOptionIndex].subheaderIndexes = [];
                }

                const addedOne = val.filter((item) => !value.includes(item));
                if (addedOne.length > 0) {
                  if (addedOne[0] === "all") {
                    let data = options.filter((item) => item.value !== "all");
                    let unSelectedItems = [];
                    data.forEach((el) => {
                      const found = value.some((item) => el.value === item);
                      if (!found) {
                        unSelectedItems.push(el);
                      }
                    });
                    // console.log("zz -> ", unSelectedItems);
                    unSelectedItems.forEach((el) => {
                      getData(el.value);
                    });
                    setValue([...value, ...unSelectedItems.map((el) => el.value)]);
                    return;
                  }
                  getData(addedOne[0]);
                }
                setValue(val);
              }}
              optionRender={(option) => {
                // console.log("options", option);
                return (
                  <Space>
                    <span role="img" aria-label={option.regUserId}>
                      {option.regUserId}
                    </span>
                  </Space>
                );
              }}
            />,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                value
                  .filter((el) => el !== "all")
                    .forEach((opCode) => {
                      const selectedOption = options.find((option) => option.value === opCode);
                      if (selectedOption) {
                        fetchReportFile(
                          "/report/transaction?xlsx=true",
                          "POST",
                          accessToken,
                          {
                            request: {
                              pageNo : selectedOption.tableRef.current.getPage(),
                              perPage: selectedOption.tableRef.current.getLimit(),
                              sort   : "",
                            },
                            ...filters,
                            operatorCode     : opCode,
                            transactionStatus: reportType,
                          },
                          `${fileName}_${selectedOption.nameEn}`.toLowerCase()
                        );
                      }
                    });
              }}
            >
              Татах
            </Button>,
          ]}
        ></Filter>

        {value.map((el, index) => {
          const selectedOption = options.find((option) => option.value === el);
          if (selectedOption.value === "all"){
            return <></>;
          }
          return (
            <React.Fragment key={index}>
              <h3>{selectedOption.label}</h3>
              <Table
                ref={selectedOption.tableRef}
                initialLoading={true}
                filters={filters}
                data={selectedOption.displayData}
                paricipment={selectedOption.name}
                el={el}
                // columns={columns}
                setHeaderIndexes={selectedOption.setHeaderIndexes}
                setSubheaderIndexes={selectedOption.setSubheaderIndexes}
                perPage={selectedOption.tablePageData.request.perPage}
                rowKey={(record) => record?.rowKey}
                getData={getData}
                value={value}
                headerIndexes={selectedOption.headerIndexes}
                subheaderIndexes={selectedOption.subheaderIndexes}
                isSuccess={isSuccess}
              />
              <div style={{ height: "30px" }}></div>
            </React.Fragment>
          );
        })}
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(transactionTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
